import { matchPath } from 'react-router-dom';

const homeRoutes = [
  "/",
  "/en",
  "/zh",
  "/zh_HANT",
  "/fr",
  "/de",
  "/hi",
  "/id",
  "/it",
  "/ja",
  "/ko",
  "/pl",
  "/pt",
  "/ru",
  "/es",
  "/th",
  "/tr",
  "/vi",
];
export const authStateKey = 'authState';
export enum AuthStates {
  authorized = 'authorized',
  unauthorized = 'unauthorized'
}

const trim = (str: string, symbol: '/' | '\\' | '|' | ' ' = ' '): string =>
  str.replace(new RegExp(`^${symbol}+|${symbol}+$`, 'g'), '');

export default function handleCreateRedirection(routeParams?: string, moduleName?: string): string {
    const configRoute = homeRoutes.find((pathname) => matchPath(pathname, `/${routeParams}`));
    if(configRoute && localStorage.getItem(authStateKey) === AuthStates.authorized) { 
      const isMobile = document.documentElement.clientWidth <= 766;
      if (isMobile) {
        window.location.href = '/explore';
      } else {
        const lang = routeParams && `/${trim(routeParams, '/')}`;
        window.history.replaceState(null, '', `${lang}/create`);
      }

        return 'create-and-home';
    } 
    return moduleName || '';
}
